<messages>["./Login", "./TotpInit"]</messages>

<template>
  <base-layout mw1>
    <v-col cols="12">
      <v-card v-if="loaded">
        <form
          novalidate
          @submit.prevent="onSubmit ()">
          <v-card-title primary-title>
            <div>
              <div
                class="text-h5"
                v-text="$t ('headline')"/>
              <div v-text="$t ('introText')"/>
            </div>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" xl="4">
                <a :href="uri" v-html="qrCode"/>
              </v-col>
            </v-row>

            <name-value-block
              :title="$t ('properties')"
              :items="[
                {name: $t('issuer'), value: jwtIssuer},
                {name: $t('name'), value: userName},
                {name: $t('secret'), value: totpInfo.secret},
                {name: $t('algorithm'), value: totpInfo.algorithm},
                {name: $t('digits'), value: totpInfo.digits},
                {name: $t('period'), value: totpInfo.period}
              ]"/>
          </v-card-text>
          <v-card-text>
            <p
              class="cgwng-subheading"
              v-text="$t ('totpValidationText')"/>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <totp-input-field :v="$v.totpCode"/>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn
              v-t="'general.button.cancel'"
              text
              @click="onCancel"/>

            <v-btn
              v-t="'general.button.ok'"
              type="submit"
              color="primary"/>
          </v-card-actions>
        </form>
      </v-card>
    </v-col>
  </base-layout>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import qrcodegen from '@/lib/qrcodegen'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import NameValueBlock from '@/app/core/components/NameValueBlock'

  import TotpInputField from './components/TotpInputField'
  import totpCodeValidation from './components/TotpInputFieldValidation'

  export default {
    name: 'TotpInit',

    components: {
      BaseLayout,
      NameValueBlock,
      TotpInputField
    },

    data () {
      return {
        loaded: false,
        totpInfo: {},
        totpCode: ''
      }
    },

    validations: {
      totpCode: totpCodeValidation
    },

    computed: {
      ...mapGetters ('auth', [
        'userName',
        'userNameUriEncoded',
        'jwtIssuer',
        'jwtIssuerUriEncoded'
      ]),

      uri () {
        const params = [
          `secret=${this.totpInfo.secret}`,
          `issuer=${this.jwtIssuerUriEncoded}`,
          `algorithm=${this.totpInfo.algorithm}`,
          `digits=${this.totpInfo.digits}`,
          `period=${this.totpInfo.period}`
        ].join ('&')

        return `otpauth://totp/${this.jwtIssuerUriEncoded}:${this.userNameUriEncoded}?${params}`
      },

      qrCode () {
        const QRC = qrcodegen.QrCode
        return QRC.encodeText (this.uri, QRC.Ecc.MEDIUM).toSvgString (2)
      }
    },

    created () {
      this.initializeTotp ()
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData',
        redirectAfterLogin: 'router/redirectAfterLogin',
        logout: 'auth/logout',
        totpLogin: 'auth/totpLogin'
      }),

      initializeTotp () {
        return this.fetchData ({
          op: 'totp/init',
          cb: data => {
            this.totpInfo = data.totpInfo
            this.loaded = true
          }
        })
      },

      onCancel () {
        this.logout ()
      },

      onSubmit () {
        if (!this.$v.$invalid) {
          this.fetchData ({
            op: 'totp/save',
            params: {
              totpInfo: this.totpInfo,
              validationCode: this.totpCode
            },
            cb: () => {
              (async () => {
                await this.totpLogin ({validationCode: this.totpCode}) &&
                  this.redirectAfterLogin (this.$router)
              }) ()
            }
          })
        } else {
          this.$v.$touch ()
        }
      }
    }
  }
</script>
