var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',{attrs:{"mw1":""}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.loaded)?_c('v-card',[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();_vm.onSubmit ()}}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',[_c('div',{staticClass:"text-h5",domProps:{"textContent":_vm._s(_vm.$t ('headline'))}}),_c('div',{domProps:{"textContent":_vm._s(_vm.$t ('introText'))}})])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","xl":"4"}},[_c('a',{attrs:{"href":_vm.uri},domProps:{"innerHTML":_vm._s(_vm.qrCode)}})])],1),_c('name-value-block',{attrs:{"title":_vm.$t ('properties'),"items":[
              {name: _vm.$t('issuer'), value: _vm.jwtIssuer},
              {name: _vm.$t('name'), value: _vm.userName},
              {name: _vm.$t('secret'), value: _vm.totpInfo.secret},
              {name: _vm.$t('algorithm'), value: _vm.totpInfo.algorithm},
              {name: _vm.$t('digits'), value: _vm.totpInfo.digits},
              {name: _vm.$t('period'), value: _vm.totpInfo.period}
            ]}})],1),_c('v-card-text',[_c('p',{staticClass:"cgwng-subheading",domProps:{"textContent":_vm._s(_vm.$t ('totpValidationText'))}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('totp-input-field',{attrs:{"v":_vm.$v.totpCode}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:('general.button.cancel'),expression:"'general.button.cancel'"}],attrs:{"text":""},on:{"click":_vm.onCancel}}),_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:('general.button.ok'),expression:"'general.button.ok'"}],attrs:{"type":"submit","color":"primary"}})],1)],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }