<template>
  <div
    v-if="hasContent"
    class="nameValueBlock">
    <div
      v-if="titleText"
      class="text-subtitle-1 primary--text"
      v-text="titleText"/>
    <name-value
      v-for="item in nonEmptyItems"
      :key="getKey (item)"
      :name="getText (item.name, item.nameKey)"
      :value="getText (item.value, item.valueKey)"
      :value-style="item.style"
      :slot-value="item.slotValue"
      :key-slot-value="item.keySlotValue"
      :value-classes="item.valueClasses"
      :link="item.link"/>
  </div>
</template>

<script>
  import NameValue from './NameValue'

  /**
   * @typedef {Object} NameValueItem
   * @property {String} name - the name
   * @property {String} nameKey - the message key used to present the name
   * @property {String} value - the value
   * @property {String} valueKey - the message key used to present the value
   */

  export default {
    name: 'NameValueBlock',

    components: {
      NameValue
    },

    props: {
      title: {
        type: String,
        default: undefined
      },
      titleKey: {
        type: String,
        default: undefined
      },
      /**
       * @type {Array.<NameValueItem>}
       */
      items: {
        type: Array,
        default: undefined
      }
    },

    computed: {
      titleText () {
        return this.getText (this.title, this.titleKey)
      },

      nonEmptyItems () {
        return this.items.filter (item => {
          return (item.name || item.nameKey) &&
            (
              (item.value && !(Array.isArray (item.value) && item.value.length === 0)) ||
              item.valueKey || this.isBoolean (item.value)
            )
        })
      },

      hasContent () {
        return this.nonEmptyItems.length > 0
      }
    },

    methods: {
      isBoolean (val) {
        return typeof val === 'boolean'
      },

      getText (text, textKey) {
        return text || (textKey ? this.$t (textKey) : '') ||
          (this.isBoolean (text) ? text : '')
      },

      getKey (item) {
        return item.extraKey
          ? item.extraKey
          : this.getText (item.name, item.nameKey)
      }
    }
  }
</script>

<style scoped>
.subtitle-1 {
  text-align: center;
}
</style>
